import React from 'react';
import TedImage from '../assets/Ted.png';
import BillImage from '../assets/BillImage.png';
import DavidImage from '../assets/DavidImage.png';
import LuImage from '../assets/Lu.png';
import GraceImage from '../assets/GraceImage.png';
import '../styles/BoardOfDirectors.css';
import { Breadcrumb } from 'semantic-ui-react';
import { Container, Row } from "react-bootstrap";

const breadcrumbSections = [
    { key: 'home', content: 'HOME', href: "/" },
    { key: 'investors', content: 'INVESTORS', href: "/investors" },
    { key: 'board', content: 'Whistleblower', active: true },
]


function WhistleBlowerPolicy() {


    return (
        <div className="whole-container">
            <div style={{ textAlign: 'left', marginLeft: '70px', marginTop: '80px' }}>
                <Breadcrumb icon='right angle' sections={breadcrumbSections} style={{ backgroundColor: '#fff' }} />
            </div>

            <div className="board-header">Whistleblower</div>

            
            <Container fluid className="board-container">

                
                <Row><div className="divider-middle"><hr /></div></Row>

                {/*TED*/}
                <Row className="justify-content-start align-items-start">
                    <div className='board-content'>
                        <h5>For any whistleblower inquiries, please contact us using the information provided below.</h5>
                        <p>Email us here: <a href="mailto:whistleblower@ttgi.com">whistleblower@ttgi.com</a></p>
                        <p>Call us here: dedicated line coming soon.</p>
                    </div>
                </Row>
                <Row><div className="divider-middle"><hr /></div></Row>
            </Container>

        </div>

    )
}

export default WhistleBlowerPolicy
